import React from 'react'
import './HomePage.css'
import { useTranslation } from 'react-i18next';
import OurServices from './ServicesComponent/OurServices';
import OurWorks from './WorksComponent/OurWorks';
import Vector from '../../assets/Vector.svg'
import ContactImage from '../../assets/HomePage/bg-download.png'
import { useNavigate } from 'react-router-dom';
import video from '../../assets/HomePage/video.mp4'
import Image3 from '../../assets/HomePage/3-img.png'
import { Helmet } from 'react-helmet';

function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>

      <Helmet>
        <title>LD InterTechInnovation - Home</title>
        <link rel="canonical" href="https://ldintertech.com/home" />
        <meta name="description" content="LD InterTechInnovation delivers innovative technology and marketing solutions, offering expert services in hardware, software, cloud systems, and digital marketing to help businesses succeed in the digital age." />
        <meta name="keywords" content="technology and marketing solutions, digital transformation services, innovative technology consulting, cloud system integration, hardware and software development, custom cloud technologies, business digital transformation, cutting-edge marketing strategies, digital marketing for businesses, future-focused business solutions, advanced hardware solutions, software solutions for enterprises, data-driven marketing, tech-driven business growth, custom software and cloud solutions, innovative digital solutions, technology consulting for businesses, smart cloud services, market positioning strategies, business innovation and technology, cloud integration experts, digital strategy consulting, end-to-end tech solutions, business transformation experts" />
        <meta name="author" content="LD InterTechInnovation" />
        <meta name="robots" content="index, follow" />
        <html lang="en" />
      </Helmet>

      <div className='homepage-section'>
        <div className='homepage-sec-div-img'>
          <h1 className='homepage-heading1'>
            CRAFTING
            <span className='video-container'>
              <video autoPlay muted loop>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </span>
            <spn className='spac-sec-res'></spn>THE DIGITAL FUTURE WITH UNRIVALED CREATIVITY
            <img src={Image3} className='techs-img-sec' alt='techs' />
            AND PRECISION
          </h1>
          <p className='homepage-subheading'>{t('MainPage.Subheading')}</p>
        </div>
      </div>



      <div className='sections-spacing'>
        <div className='homepage-about-section'>
          <div className='about-section-div'>
            <h2 className='homepage-about-heading'>{t('MainPage.AboutUs')}<span style={{ fontStyle: "normal", paddingLeft: "10px" }}> {t('MainPage.AboutUs1')}</span></h2>
          </div>
          <div className='about-section-div1'>
            <p className='homepage-about-text'>{t('MainPage.AboutUsText')}</p>
            <p className='homepage-about-text'>{t('MainPage.AboutUsText1')}</p>
            <button className='about-btn' onClick={() => navigate("/about")}>{t('MainPage.AboutUs')}</button>
          </div>
        </div>


        <div className='homepage-services-section'>
          <h2 className='homepage-about-heading'>{t('MainPage.OurServices')}<span style={{ fontStyle: "normal", paddingLeft: "10px" }}> {t('MainPage.OurServices1')}</span></h2>
          <OurServices />
        </div>


        <div className='homepage-services-section'>
          <h2 className='homepage-about-heading'>{t('MainPage.OurWork')}<span style={{ fontStyle: "normal", paddingLeft: "10px" }}> {t('MainPage.OurWork1')}</span></h2>
          <OurWorks />
        </div>


        <div className='homepage-services-section'>
          <div className='work-section-div'>
            <div className='work-sec-div'>
              <h2 className='homepage-about-heading supperpoer-sec'>{t('MainPage.Superpowers')}<span style={{ fontStyle: "normal", paddingLeft: "10px" }}>  {t('MainPage.Superpowers1')}</span></h2>
            </div>
            <div className='work-sec-div'>
              <div className='superpowers-section'>
                <img src={Vector} className='homepage-vector-img' alt='vector' />
                <p className='homepage-about-text'>{t('MainPage.SuperpowersText1')}</p>
              </div>
              <div className='superpowers-section'>
                <img src={Vector} className='homepage-vector-img' alt='vector' />
                <p className='homepage-about-text'>{t('MainPage.SuperpowersText2')}</p>
              </div>
              <div className='superpowers-section'>
                <img src={Vector} className='homepage-vector-img' alt='vector' />
                <p className='homepage-about-text'>{t('MainPage.SuperpowersText3')}</p>
              </div>
              <div className='superpowers-section'>
                <img src={Vector} className='homepage-vector-img' alt='vector' />
                <p className='homepage-about-text'>{t('MainPage.SuperpowersText4')}</p>
              </div>
            </div>
          </div>
        </div>

        <div className='homepage-services-section'>
          <div className='image-container'>
            <img src={ContactImage} className='contact-img-sec' alt='get-in-touch' />
            <div className='centered-content'>
              <h2 className='last-sec-heading'>{t('MainPage.LastText')}</h2>
              <button onClick={() => navigate("/contact")} className='nav-items-text1 get-btn-sec'>{t('MainPage.GetinTouch')}</button>
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default HomePage